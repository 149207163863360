import * as types from './actionConstants'

export const showToast = (payload) => ({
    type: types.SHOW_TOAST,
    payload
})

export const isLoadingTrue = () => ({
    type: types.IS_LOADING_TRUE,
})

export const isLoadingFalse = () => ({
    type: types.IS_LOADING_FALSE,
})