import { combineReducers } from "redux"
import userReducer from "./reducer/userReducer";
import LoaderReducer from "./reducer/LoaderReducer";


const rootReducer = combineReducers({
    userReducer,
    LoaderReducer
});


export default rootReducer;
