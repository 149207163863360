import React, { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";
import Coupons1 from "./pages/Coupons1";
import Coupons2 from "./pages/Coupons2";
import CouponsTab from "./pages/CouponsTab";
import MerchantsQrcode from "./pages/MerchantsQrcode";
import AdminLogin from "./pages/AdminLogin";
import MerchantsCoupon from "./pages/MerchantsCoupon";
import CouponsPage from "./pages/CouponsPage";
import CouponsViewPage from "./pages/CouponsViewPage";
import MerchantsDetails from "./pages/MerchantsDetails";
import MerchantsTab from "./pages/MerchantsTab";
import MerchantsData from "./pages/MerchantsData";
import PaymentPage from "./pages/PaymentPage";
import { AuthContext } from "./context/Context";
import { useSelector } from "react-redux";
import Loader from "./component/Loader";
import Footer from "./component/Footer";

const RoutesHolder = () => {
  const { isLoading } = useSelector((state) => state.LoaderReducer)
  const [couponsData, setCouponsData] = useState({
    merchantId: "",
    couponStart: "",
    couponExpiry: "",
    couponDiscount: "",
    minAmount: "",
    maxAmount: "",
    couponStatus: "",
    couponType: "",
  });
  return (
    <AuthContext.Provider value={{ couponsData, setCouponsData }}>
      <Routes>
        <Route
          exact
          path="/merchantsdetails"
          element={
            <ProtectedRoutes>
              <MerchantsDetails />
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/merchantstab"
          element={
            <ProtectedRoutes>
              <MerchantsTab />
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/merchantsqrcode"
          element={
            <ProtectedRoutes>
              <MerchantsQrcode />
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/couponstab"
          element={
            <ProtectedRoutes>
              <CouponsTab />
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/merchantscoupon"
          element={
            <ProtectedRoutes>
              <MerchantsCoupon />
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/couponsviewpage/:merchantId"
          element={
            <ProtectedRoutes>
              <CouponsViewPage />
            </ProtectedRoutes>
          }
        />

        <Route
          exact
          path="/merchantsdata/:umid/:uid/:status"
          element={
            <ProtectedRoutes>
              <MerchantsData />
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/editmerchantsdata/:editId/:umid/:status"
          element={
            <ProtectedRoutes>
              <MerchantsData />
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/editmerchantsdetails/:uid/:umid"
          element={
            <ProtectedRoutes>
              <MerchantsData />
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/coupons1"
          element={
            <ProtectedRoutes>
              <Coupons1 />
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/coupons2"
          element={
            <ProtectedRoutes>
              <Coupons2 />
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/couponspage"
          element={
            <ProtectedRoutes>
              <CouponsPage />
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/payments"
          element={
            <ProtectedRoutes>
              <PaymentPage />
            </ProtectedRoutes>
          }
        />
        <Route exact path={"/"} element={<AdminLogin />} />
      </Routes>
      <Loader loading={isLoading} />
    </AuthContext.Provider>
  );
};

export default RoutesHolder;
