import React from "react";
import "./navbar.css";
import "animate.css";
const Navbar = () => {
  return (
    <div className="flex justify-between bg-gray-700 items-center py-2">
      <div className="tablet:ml-4 ml-2">
        <img className="tablet:w-[170px] w-[90px]" src="/image/Logo.png" />
      </div>

      <div className="flex  tablet:space-x-8 space-x-4 bg-gray-700 text-white tablet:text-[25px] text-[16px] tablet:mr-6 mr-4">
        <div>Coupons</div>
        <div>Merchants</div>
        <div>Qr code</div>
      </div>
    </div>
  );
};

export default Navbar;
