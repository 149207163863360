import * as yup from 'yup';
const name = /^[a-zA-Z]+$/
const number = (/^[0-9\b]+$/)
const gst = /^([0-9]{2}[a-zA-Z]{4}([a-zA-Z]{1}|[0-9]{1})[0-9]{4}[a-zA-Z]{1}([a-zA-Z]|[0-9]){3}){0,15}$/

// --------------------LoginValidation------------------------------------------------------------
export const LoginValidation = yup.object().shape({
    email: yup.string().required('Please enter email Address.')
        .matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Please enter valid email.'),
    password: yup.string().required('Please enter password.')
})

// --------------------BrandValidation -------------------------------------------------------------
export const MerchantsValidation = yup.object().shape({
    merchant_name: yup.string().required('Please enter merchant name.'),

    merchant_location: yup.string().required('Please enter location.'),

    merchant_poc_name: yup.string().required('Please enter brand poc name.'),

    merchant_contact: yup.string().required('Please enter contact number.')
        .matches(number, 'Please enter only number').min(10, "Please enter valid number").max(12, 'max limit is 12'),

    // merchant_url: yup.string().required('Please enter url.').min(5, 'Please enter merchant name'),
    merchant_url: yup.string().required('Please enter merchant name'),
    merchant_category: yup.string().required('Please enter brand category.')
        .matches(name, 'Only alphabets,underscore,and hyphens are acceptable'),

    merchant_pan: yup.string().required('Please enter pan number.'),


    merchant_gst: yup.string().required('Please enter gst number.')
        .matches(gst, 'Only alphabets and number are acceptable').max(16, 'max limit is 16'),

    merchant_logo: yup.string().required('Please upload image.'),

    merchant_upi: yup.string().required('Please enter upi-id.'),

    ifsc_code: yup.string().required('Please enter ifsc-code.'),

    account_holder_name: yup.string().required('Please enter account holder name.'),

    account_number: yup.string().required('Please enter Account number.'),

    bank_name: yup.string().required('Please enter Bank name.').nullable(),

    state: yup.string().required('Please enter state.').nullable(),

    area_name: yup.string().required('Please enter area name.').nullable(),

    address: yup.string().required('Please enter address.').nullable(),

    city: yup.string().required('Please enter city.').nullable(),

    pin_code: yup.string().required('Please enter pin code.').nullable(),

});

export const Coupons1Validation = yup.object().shape({
    merchantId: yup.string().required('Please select merchant name'),
    couponCount: yup.string().required('Please enter total coupon'),
    couponStart: yup.string().required('Please enter start date'),
    couponExpiry: yup.string().required('Please enter expiry date'),

})
export const Coupons2Validation = yup.object().shape({
    couponDiscount: yup.string().required('please enter coupon discount'),
    minAmount: yup.string().required('Please enter min amount'),
    maxAmount: yup.string().required('Please enter max amount'),
    couponType: yup.string().required('Please select offer'),
    inburgPercent: yup.string().required('Please enter inburg percent'),
})

export const StartEndDateValidation = yup.object().shape({
    Start: yup.string().required('Please enter start date'),
    End: yup.string().required('Please enter end date'),
})