import { BrowserRouter } from "react-router-dom";
import RoutesHolder from "./RoutesHolder";
import "./input.css";

import React from "react";
import { HIDE_TOAST } from "./redux/action/actionConstants";
import { Alert as MuiAlert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Footer from "./component/Footer";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App() {
  const dispatch = useDispatch();

  const { type, message, isVisible } = useSelector(
    (state) => state.userReducer
  );
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({
      type: HIDE_TOAST,
    });
  };
  return (
    <>
      <Snackbar
        open={isVisible}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <div className="App min-h-screen">
        <BrowserRouter>
          <RoutesHolder />
          {/* <Footer /> */}
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
