import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postReq } from "../utils/ApiHandler";
import Cookies from "js-cookie";
import { showToast } from "../redux/action";
import { LoginValidation } from "../utils/Auth";
import { isYupError } from "../utils/Yup";
import { parseYupError } from "../utils/Yup";

const AdminLogin = () => {
  const [errors, setErrors] = useState();
  const [hidePass, sethidePass] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [user, setUser] = useState({});

  const [adminLogin, setAdminLogin] = useState({
    email: "",
    password: "",
  });

  const handleInput = (event) => {
    const { name, value } = event.target;
    setAdminLogin({ ...adminLogin, [name]: value });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleForm = async (event) => {
    event.preventDefault();
    try {
      setErrors({});
      await LoginValidation.validate(adminLogin, {
        abortEarly: false,
      });
      postReq(`/admin/signin`, adminLogin)
        .then((res) => {
          if (res.status) {
            if (res.data.data) {
              Cookies.set(
                "user",
                JSON.stringify({ token: res.data.data.token })
              );
              setUser((prev) => {
                return {
                  ...prev,
                  token: res.data.data.token,
                  user: res.data.user,
                  isUserLogin: true,
                };
              });
              navigate("/couponstab");
              dispatch(
                showToast({ type: "success", message: "Login successfully." })
              );
            } else {
              dispatch(showToast({ type: "error", message: res.data.error }));
            }
          } else {
            dispatch(showToast({ type: "error", message: res.error }));
          }
        })
        .catch((e) => {
          console.log("error", e);
        });
    } catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
      } else {
        console.log("error");
      }
    }
  };

  return (
    <div className=" flex justify-center items-center h-screen">
      <form action="#" method="post" onSubmit={handleForm}>
        <div className="w-[350px] tablet:w-[700px] border-2 border-gray-100 tablet:p-[45px] px-6 py-6">
          <div className="w-full h-full bg-gray-200 border-2 border-gray-200 flex justify-center items-center">
            <div className="tablet:space-y-[25px] tablet:w-[75%] w-full md:py-12 md:p-0 p-6">
              <div>
                <h1 className="md:text-[25px] text-[20px] text-center ">
                  Admin Login
                </h1>
              </div>
              <div className="flex flex-col md:space-y-[40px] space-y-4 text-end">
                <div className="tablet:pt-2 pt-4 flex tablet:space-x-[53px] lg:space-x-[61px] text-[20px] tablet:items-center text-start tablet:flex-row flex-col">
                  <label className="md:text-[20px] text-[15px]">Email</label>
                  <div>
                    <input
                      className="focus:border-gray-400 focus:ring-gray-500 border-gray-300 tablet:text-[16px]
                       text-[14px] w-full"
                      type="email"
                      name="email"
                      value={adminLogin.email}
                      onChange={handleInput}
                      placeholder="Enter Email Address"
                      error={errors?.email}
                    ></input>
                    <p className="tablet:text-[16px] text-[13px]">
                      {errors ? (
                        <span style={{ color: "red" }}>{errors.email}</span>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                </div>

                <div className="flex flex-wrap tablet:space-x-[24px] text-[20px] tablet:items-center text-start tablet:flex-row flex-col">
                  <label className="md:text-[20px] text-[15px]">Password</label>
                  <div className="flex flex-col ">
                    <div className="relative">
                      <input
                        className="focus:border-gray-400 focus:ring-gray-500 border-gray-300 tablet:text-[16px] w-full text-[14px]"
                        type={hidePass ? "text" : "password"}
                        name="password"
                        value={adminLogin.password}
                        onChange={handleInput}
                        placeholder="Enter Password"
                        error={errors?.password}
                      />

                      <span
                        onClick={() => sethidePass(!hidePass)}
                        className="absolute right-0 lg:top-[50%] top-[50%] cursor-pointer translate-y-[-50%] bg-[#2977C9] h-[40px] w-[40px] text-white grid place-items-center"
                      >
                        {hidePass ? (
                          <i className="fa-solid fa-eye"></i>
                        ) : (
                          <i className="fa-solid fa-eye-slash"></i>
                        )}
                      </span>
                    </div>
                    <p className="tablet:text-[16px] text-[13px]">
                      {errors ? (
                        <span style={{ color: "red" }}>{errors.password}</span>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <button className="bg-blue-600 text-white tablet:mt-[15px] mt-4 md:px-[20px] md:py-[6px] px-2 py-1 tablet:text-[16px] text-[15px]">
                  login
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AdminLogin;
