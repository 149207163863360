import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../component/Footer";
import { showToast } from "../redux/action";
import { getApiReq } from "../utils/ApiHandler";

const MerchantsDetails = () => {
  const viewid = useParams();
  const navigate = useNavigate();

  const [brandAllData, setBrandAllData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getAllBrandData();
  }, []);

  const getAllBrandData = () => {
    getApiReq(`/admin/Get-all-brand`).then((res) => {
      if (res.status) {
        setBrandAllData(res.data.data);
      } else {
        dispatch(showToast({ type: "error", message: res.error }));
      }
    });
  };

  const viewdata = brandAllData.filter((item, index) => {
    return item.brand_id == viewid.id;
  });

  // ----------------------------Coupons Click-------------------------------
  const handleCoupon = () => {
    navigate("/merchantstab");
  };

  return (
    <div className="bg-gray-200 min-h-screen w-full text-[18px]">
      <form method="post" action="#">
        <div className="bg-gray-600 flex items-center justify-between py-2">
          <img
            className="tablet:w-[170px] w-[110px] tablet:ml-4 ml-2"
            src="/image/Logo.png"
          />

          <div>
            <button
              onClick={handleCoupon}
              className="bg-white text-gray-500 py-1 tablet:px-3 px-2 tablet:text-[20px] text-[16px] tablet:mr-6 mr-4"
            >
              Coupons
            </button>
          </div>
        </div>

        <div className="flex justify-center">
          <p className="font-bold mb-2 tablet:text-[25px] text:[16px] mt-10 text-gray-700">
            Merchants Details
          </p>
        </div>

        <div className="w-full tablet:text-[18px] text-[15px] max-w-[1200px] gap-20 tablet:mt-10 mt-6 mx-auto flex lg:justify-between tablet:justify-center lg:items-start tablet:items-center lg:flex-row flex-col">
          <div className="tablet:space-y-10 space-y-6 tablet:w-[80%] lg:w-[100%] w-full mx-auto flex flex-col tablet:text-left">
            <div className="flex tablet:items-center gap-x-6 tablet:flex-row flex-col tablet:mx-0 mx-6">
              <label className="tablet:w-1/3 flex-shrink-0 leading-7 tablet:leading-0">
                Store or brand name:
              </label>
              <input
                className="border-none w-full tablet:text-[16px] text-[14px]"
                type="text"
                placeholder="Enter name"
                readOnly
                disabled
              />
            </div>

            <div className="flex tablet:items-center gap-x-6 tablet:flex-row flex-col tablet:mx-0 mx-6">
              <label className="w-1/3 flex-shrink-0 leading-7 tablet:leading-0">
                Email address :
              </label>
              <input
                className="border-none w-full tablet:text-[16px] text-[14px]"
                type="email"
                placeholder="Enter email address"
                readOnly
                disabled
              />
            </div>

            <div className="flex tablet:items-center gap-x-6 tablet:flex-row flex-col tablet:mx-0 mx-6">
              <label className="w-1/3 flex-shrink-0 leading-7 tablet:leading-0">
                Location :
              </label>
              <input
                className="border-none w-full tablet:text-[16px] text-[14px]"
                type="text"
                placeholder="Enter location"
                readOnly
                disabled
              />
            </div>

            <div className="flex tablet:items-center gap-x-6 tablet:flex-row flex-col tablet:mx-0 mx-6">
              <label className="w-1/3 flex-shrink-0 leading-7 tablet:leading-0">
                POC name :
              </label>
              <input
                className="border-none w-full tablet:text-[16px] text-[14px]"
                type="text"
                placeholder="Enter POC name"
                readOnly
                disabled
              />
            </div>

            <div className="flex tablet:items-center gap-x-6 tablet:flex-row flex-col tablet:mx-0 mx-6">
              <label className="w-1/3 flex-shrink-0 leading-7 tablet:leading-0">
                Contact no :
              </label>
              <input
                className="border-none w-full tablet:text-[16px] text-[14px]"
                type="number"
                placeholder="Enter contact no."
                readOnly
                disabled
              />
            </div>

            <div className="flex tablet:items-center gap-x-6 tablet:flex-row flex-col tablet:mx-0 mx-6">
              <label className="w-1/3 flex-shrink-0 leading-7 tablet:leading-0">
                Merchant URL:
              </label>
              <input
                className="border-none w-full tablet:text-[16px] text-[14px]"
                type="number"
                placeholder="Enter Merchant URL"
                readOnly
                disabled
              />
            </div>

            <div className="flex tablet:items-center gap-x-6 tablet:flex-row flex-col tablet:mx-0 mx-6">
              <label className="tablet:w-1/3 flex-shrink-0 leading-7 tablet:leading-0">
                Select category :
              </label>
              <select
                disabled
                className="w-full border-none tablet:text-[16px] text-[14px]"
              >
                <option>Select</option>
                <option>A</option>
                <option>B</option>
                <option>C</option>
              </select>
            </div>
          </div>

          <div className="w-full tablet:text-[18px] text-[15px] max-w-[1200px] mx-auto flex lg:justify-between tablet:justify-center lg:items-start tablet:items-center lg:flex-row flex-col">
            <div className="tablet:space-y-10 space-y-6 tablet:w-[80%] lg:w-[100%] mx-6 flex flex-col tablet:text-left">
              <div className="flex tablet:items-center gap-x-6 tablet:flex-row flex-col">
                <label className="tablet:w-1/3 flex-shrink-0 leading-7 tablet:leading-0">
                  PAN number :
                </label>
                <input
                  className="border-none w-full tablet:text-[16px] text-[14px]"
                  type="text"
                  placeholder="Enter PAN number"
                  readOnly
                  disabled
                />
              </div>

              <div className="flex tablet:items-center gap-x-6 tablet:flex-row flex-col">
                <label className="tablet:w-1/3 flex-shrink-0 leading-7 tablet:leading-0">
                  GST number :
                </label>
                <input
                  className="border-none w-full tablet:text-[16px] text-[14px]"
                  type="text"
                  placeholder="Enter GST number"
                  readOnly
                  disabled
                />
              </div>
              <div className="flex tablet:items-center gap-x-6 tablet:flex-row flex-col">
                <label className="w-1/3 flex-shrink-0 leading-7 tablet:leading-0">
                  UPI id :
                </label>
                <input
                  className="border-none w-full tablet:text-[16px] text-[14px]"
                  type="text"
                  placeholder="Enter UPI id"
                  readOnly
                  disabled
                />
              </div>
              <div className="flex tablet:items-center gap-x-6 tablet:flex-row flex-col">
                <label className="w-1/3 flex-shrink-0 leading-7 tablet:leading-0">
                  UMID:
                </label>
                <input
                  className="border-none w-full tablet:text-[16px] text-[14px]"
                  type="text"
                  placeholder="Enter UMID id"
                  readOnly
                  disabled
                />
              </div>

              <div className="flex tablet:items-center gap-x-6 tablet:flex-row flex-col">
                <label className="w-1/3 flex-shrink-0 leading-8">Logo :</label>
              </div>
              <div className="flex items-center gap-x-6">
                <label className="w-1/3 flex-shrink-0 leading-7 tablet:leading-0">
                  Package :
                </label>
                <input
                  className="border-none w-[40%] tablet:text-[16px] text-[14px]"
                  type="text"
                  placeholder="Free"
                  readOnly
                  disabled
                />
                <div>
                  <button className="bg-gray-600 py-2 tablet:px-4 px-2 text-white tablet:text-[16px] text:text-[17px]">
                    Upgrade
                  </button>
                </div>
              </div>

              <div className="flex justify-center pb-10">
                <button className="bg-gray-600 py-2 tablet:px-5 px-3 text-white tablet:text-[20px] text:text-[17px] tablet:mt-4 mt-4 tablet:mr-16 lg:mr-10">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MerchantsDetails;
