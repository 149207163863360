import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getApiReq } from "../utils/ApiHandler";
import { showToast } from "../redux/action";
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import moment from "moment";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import { StartEndDateValidation } from "../utils/Auth";
import { isYupError, parseYupError } from "../utils/Yup";
import Footer from "../component/Footer";

const PaymentPage = () => {
  const [getAllPayment, setGetAllPayment] = useState();
  const [search, setSearch] = useState('');
  const [errors, setErrors] = useState();
  const [date, setDate] = useState({
    Start: '',
    End: ''
  });
  const dispatch = useDispatch();

  const getCoupon = () => {
    console.log(search, 'search')
    getApiReq(`/admin/get-all-payment?search=${search}&startDate=${date.Start}&endDate=${date.End}`).then((res) => {
      if (res.status) {
        console.log(res.data.data)
        setGetAllPayment(res.data.data);
      } else {
        dispatch(showToast({ type: "error", message: res.error }));
      }
    });
  }
  useEffect(() => {
    getCoupon()
  }, [search]);

  const handleChange = (event) => {
    const { value } = event.target;
    setSearch(value);

  };

  const dateChange = (name, newValue) => {

    setDate({ ...date, [name]: moment(new Date(newValue)).format("YYYY-MM-DD hh:mm:ss a") })

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const getCouponData = async () => {
    try {
      setErrors({});
      await StartEndDateValidation.validate(date, {
        abortEarly: false,
      });
      getCoupon()

    } catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
        console.log(parseYupError(error));
      } else {
        console.log("error");
      }
    }
  }

  // const getAllCoponsData = () => {
  //   getApiReq(`/admin/get-coupons`).then((res) => {
  //     if (res.status) {
  //       handleCoponData(res.data.data);
  //     } else {
  //       dispatch(showToast({ type: "error", message: res.error }));
  //     }
  //   });
  // };

  // const handleCoponData = (data) => {
  //   let tempArray = [];
  //   let tempObject = [];
  //   data?.map((item) => {
  //     if (!tempArray.includes(item.merchant_name)) {
  //       tempArray.push(item.merchant_name)
  //       tempObject.push({ merchant_name: item.merchant_name, merchant_id: item.merchant_id, offer_id: item.offer_id })
  //     }
  //     else {
  //       return
  //     }
  //   })
  //   setGetAllPayment(tempObject)
  // }


  const handleLogout = () => {
    Cookies.remove('user');
    navigate('/')
  }

  // -------------------------------Post api call from particular id-------------------
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex justify-between bg-gray-700 items-center py-2">
        <div className="tablet:ml-4 ml-2 tablet:w-[170px] w-[150px]">
          <NavLink to="/merchantsqrcode"><img className="w-full" src="/image/Logo.png" /></NavLink>
        </div>
        <div className="flex flex-wrap justify-end tablet:justify-start gap-3 tablet:gap-8 bg-gray-700 text-white tablet:text-[25px] text-[16px] tablet:mr-6 mr-4">
          <NavLink to="/couponstab">Coupons</NavLink>
          <NavLink to="/coupons1">Create-Coupons</NavLink>
          <NavLink to="/merchantstab">Merchants</NavLink>
          <NavLink to="/payments">Payments</NavLink>
          <NavLink to="/merchantsqrcode">QR code</NavLink>
          <button onClick={handleLogout}>Logout</button>
        </div>
      </div>

      <div className="flex p-4 justify-start bg-[#CCCCCC]">
        <div className="tablet:mr-2">
          <input
            className="w-[220px] focus:border-gray-300 focus:ring-gray-400"
            type="text"
            name="merchant_search"
            placeholder="Search merchant"
            onChange={handleChange}
          />
        </div>


        <div className="flex gap-5">

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="flex tablet:flex-row flex-col  tablet:text-[18px] text-[16px] tablet:leading-0 leading-10 ">

              <div>
                <DateTimePicker
                  label="Start date"
                  value={date.Start}
                  onChange={(e) => dateChange("Start", e)}
                  renderInput={(params) => <TextField {...params} />}
                />
                <p className="tablet:text-[16px] text-[13px]">
                  {errors ? (
                    <span style={{ color: "red" }}>
                      {errors.Start}
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            </div>

            <div className="flex tablet:flex-row flex-col tablet:leading-0 leading-10">
              <div>
                <DateTimePicker
                  label="End date"
                  value={date.End}
                  onChange={(e) => dateChange("End", e)}
                  renderInput={(params) => <TextField {...params} />}
                />
                <p className="tablet:text-[16px] text-[13px]">
                  {errors ? (
                    <span style={{ color: "red" }}>
                      {errors.End}
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            </div>
          </LocalizationProvider>


          <button className="bg-gray-500 w-[150px] h-[56px] rounded-md text-white py-2 px-2.5 tablet:text-[18px] text-[14px]" onClick={getCouponData} >
            Search
          </button>
        </div>

      </div>
      {/* ablet:w-[200%] table-auto lg:w-[150%] w-[300%] */}
      <div className=" w-full ">
        <table className="table-auto w-full  " >
          <thead className="sticky top-0">
            <tr className="bg-gray-600 text-white tablet:text-[20px] text-[16px]">
              <th className="w-1/10 border-l-2 border-b-2 p-3">Merchants Name</th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Total Amount<span> (&#8377;) </span></th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Merchant Amount<span> (&#8377;) </span></th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Inburg Amount<span> (&#8377;) </span></th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Discount Value</th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Payment Id</th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Order Id</th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Payment Status</th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Payment Settlement - In progress</th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Payment Settlement - Done</th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Date</th>
            </tr>
          </thead>
          <tbody>
            {
              (getAllPayment?.length > 0) ?

                getAllPayment?.map((item, index) => {

                  return (
                    <tr className="tablet:text-[18px] text-[14px]" key={index}>

                      {/* <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300 ">
                        <button className="text-sky-700 capitalize text-[20px]" onClick={() => navigate(`/couponsviewpage/${item.merchant_id}`)}>{item.merchant_name}</button>
                      </td> */}
                      <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{item.merchant_name}</td>
                      <td className=" p-2 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{(item.amount) ? item.amount : 0}</td>
                      <td className=" p-2 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{(item.merchant_amount) ? item.merchant_amount : 0}</td>
                      <td className=" p-2 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{(item.inburg_amount) ? item.inburg_amount : 0}</td>
                      <td className=" p-2 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{item.offer_value ? item.offer_value : 'U/A'}</td>
                      <td className=" p-0 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{item.razorpay_payment_id ? item.razorpay_payment_id : 'U/A'}</td>
                      <td className=" p-1 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{item.razorpay_order_id ? item.razorpay_order_id : 'U/A'}</td>
                      <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{item.payment_status ? item.payment_status : 'U/A'}</td>


                      <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{item.payment_date ? moment(new Date(item.payment_date)).format("DD-MM-YYYY hh:mm:ss a") : '--'}</td>
                      <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{item.merchant_on_hold_until ? moment(new Date(item.merchant_on_hold_until)).format("DD-MM-YYYY hh:mm:ss a") : '--'}</td>

                      <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{item.payment_date ? moment(new Date(item.payment_date)).format("DD-MM-YYYY hh:mm:ss a") : '--'}</td>
                    </tr>
                  );
                }) :
                <tr className="tablet:text-[18px] text-[14px]">
                  <td colSpan={11} className=" text-center border-b-2 border-gray-300 py-2">data not found</td>
                </tr>}
          </tbody>
        </table>
      </div>
    </div >
  );
};

export default PaymentPage;
