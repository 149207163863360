import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Footer from "../component/Footer";

const CouponsPage = () => {

  const navigate = useNavigate()
  const datatable = [
    {
      name: "Beardo",
      coupon: "50",
      coupons: "30",
      redeemed: "10",
      Remain: "23,350.00",
      Demo: "1984.75",
    },

    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
  ];

  const handleLogout = () => {
    Cookies.remove('user');
    navigate('/')
  }
  return (
    <div>
      <div className="flex justify-between bg-gray-700 items-center py-2">
        <div className="tablet:ml-4 ml-2">
          <NavLink to="/merchantsqrcode"><img className="tablet:w-[170px] w-[110px]" src="/image/Logo.png" /></NavLink>
        </div>

        <div className="flex  tablet:space-x-8 space-x-3 bg-gray-700 text-white tablet:text-[25px] text-[16px] tablet:mr-6 mr-4">
          <NavLink to="/couponstab">Coupons</NavLink>
          <NavLink to="/merchantstab">Merchants Details</NavLink>
          <button onClick={handleLogout}>Logout</button>
        </div>
      </div>

      <div className="flex tablet:flex-row flex-col tablet:p-4 p-3 justify-between bg-[#CCCCCC]">
        <div className="">
          <input
            className="w-[220px] focus:border-gray-300 focus:ring-gray-400"
            type="text"
            name="daterange"
            value=""
          />
        </div>

        <div className="flex items-center gap-4 tablet:mr-4 tablet:mt-0 mt-3">
          <label
            for="default-toggle"
            class="inline-flex relative items-center cursor-pointer"
          >
            <input
              type="checkbox"
              value=""
              id="default-toggle"
              class="sr-only peer"
            />
            <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
            <span class="ml-3 tablet:text-[18px] text-[15px]">
              Redeemed only
            </span>
          </label>
        </div>
      </div>

      <div className="">
        <table className="tablet:w-[200%] lg:w-[100%]">
          <thead className="sticky top-0">
            <tr className="bg-gray-600 text-white tablet:text-[20px] text-[16px]">
              <th className="w-1/10 border-l-2 border-b-2 p-3">
                Merchants name
              </th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">
                Coupons issued
              </th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">
                Coupons redeemed
              </th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">
                {" "}
                Coupons remaining<span> (&#8377;)</span>
              </th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">
                Total sales value<span>(&#8377;);</span>
              </th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">
                Total commission<span> (&#8377;)</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {datatable.map((item) => (
              <tr className="tablet:text-[17px] text-[13px]">
                <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                  {item.name}
                </td>
                <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                  {item.coupon}
                </td>
                <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                  {item.coupons}
                </td>
                <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                  {item.redeemed}
                </td>
                <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                  {item.Remain}
                </td>

                <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                  {item.Demo}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CouponsPage;
