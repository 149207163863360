import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { isUserLoggedIn } from './utils/basicReq';

const ProtectedRoutes = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (isUserLoggedIn()) ?
    children : <Navigate to="/" />;
};

export default ProtectedRoutes;