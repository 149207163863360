import React, { useEffect, useState } from "react";
import { postApiReq } from "../utils/ApiHandler";
import { isYupError, parseYupError } from "../utils/Yup";
import { isLoadingTrue, showToast } from "../redux/action";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { MerchantsValidation } from "../utils/Auth";
import { getAllCategories } from "../utils/basicReq";
import ENV from "../utils/env";
import Loader from "../component/Loader";
import { IoMdArrowRoundBack } from 'react-icons/io';


const MerchantsData = () => {
  const [flag, setFlag] = useState(true);
  const [merchentId, setMerchentId] = useState("")
  const [image, setImage] = useState();
  const [isLoading, setIsLoading] = useState(false);


  const [errors, setErrors] = useState();
  const dispatch = useDispatch();
  const $ = window.$;
  const navigate = useNavigate();
  const { umid, uid, status, editId } = useParams();


  const [addMerchant, setAddMerchants] = useState({
    merchant_name: "",
    merchant_email: "",
    merchant_location: "",
    merchant_poc_name: "",
    merchant_contact: "",
    merchant_category: "",
    merchant_logo: "",
    merchant_pan: "",
    merchant_gst: "",
    merchant_upi: "",
    merchant_umid: umid,
    merchant_package: "free",
    merchant_url: `https://www.inburg.com/`,
    merchant_uid: Number(uid),
    merchant_status: "",
    business_type: "individual",
    ifsc_code: "",
    account_holder_name: "",
    account_number: "",
    bank_name: "",
    state: "",
    city: "",
    area_name: "",
    pin_code: "",
    address: ""
  });

  const makeUrl = (value) => {

    if (value.indexOf('https://www.inburg.com/') === -1) {
      setAddMerchants({ ...addMerchant, 'merchant_url': `https://www.inburg.com/` });
    } else {
      let changeValue = value.replace('https://www.inburg.com/', '');
      console.log(changeValue, 'changeValue')
      setAddMerchants({ ...addMerchant, 'merchant_url': `https://www.inburg.com/${changeValue}` });
    }
  }
  const handleChange = (event) => {

    const { name, value } = event.target;
    if (name === 'merchant_url') {
      makeUrl(value);
    }
    else if (name === 'merchant_name') {
      let merchant_url = `https://www.inburg.com/` + value.replace(/[^\w\\s]/gi, '-').toLowerCase();
      setAddMerchants({ ...addMerchant, [name]: value, 'merchant_url': merchant_url });

    }
    else {
      setAddMerchants({ ...addMerchant, [name]: value });
    }

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleChangeStatus = (value) => {
    console.log('value', value)
    setAddMerchants({ ...addMerchant, merchant_status: value });
    setErrors({
      ...errors,
      merchant_status: "",
    });
  }
  const handleForm = async (event) => {
    event.preventDefault();
    const data = new FormData();
    for (const [key, value] of Object.entries(addMerchant)) {
      data.append(key, value);
    }

    try {
      setErrors({});
      await MerchantsValidation.validate(addMerchant, {
        abortEarly: false,
      });
      setIsLoading(true);

      console.log(data, 'data ')

      postApiReq(`/admin/add-merchant`, data)

        .then((res) => {
          console.log(res.data.status, 'res.status')
          if (res.data.status === 0) {
            setIsLoading(false);
            dispatch(showToast({ type: "error", message: res.data.error.description }));
          } else {
            console.log(res.status)
            if (res.status) {
              dispatch(
                showToast({
                  type: "success",
                  message: "Merchant Data Added successfully.",
                })
              );
              navigate("/merchantstab");
              // statusApiCall();
              setIsLoading(false)
            } else {
              setIsLoading(false);
              dispatch(showToast({ type: "error", message: res.error }));
            }
          }
        })
        .catch((e) => {
          setIsLoading(false);
          console.log("error", e);
        });

    } catch (error) {
      if (isYupError(error)) {
        console.log(error);
        setErrors(parseYupError(error));
      } else {
        console.log("error");
      }
    }
  };

  // const statusApiCall = () => {
  //   postApiReq(`/admin/get-qr-code-detail-status`, {
  //     merchant_uid: Number(uid),
  //     merchant_code_status: Number(1),
  //   })
  //     .then((res) => {
  //     })
  //     .catch((e) => {
  //       console.log("error", e);
  //     });
  // };

  // ------------------------------------merchantQR page Edit Button Api call----------------
  useEffect(() => {
    if (uid || editId) {
      if (editId && status && umid) {
        getEditData()
        setFlag(false);
      }
      else if (umid && uid && status) {
        setFlag(true)
      }
      else if (uid) {
        getUmidEdit();
        setFlag(false);
      }
    } else {
      setFlag(true);
    }
  }, [uid, editId]);

  useEffect(() => {
    //setCategories(getAllCategories());
  }, []);
  const getUmidEdit = () => {
    postApiReq(`/admin/edit-merchant-umid`, { merchant_uid: uid })
      .then((res) => {
        setMerchentId(res.data.data[0].merchant_id)
        setImage(res.data.data[0].merchant_logo)
        setAddMerchants({
          merchant_name: res.data.data[0].merchant_name,
          merchant_email: res.data.data[0].merchant_email,
          merchant_location: res.data.data[0].merchant_location,
          merchant_poc_name: res.data.data[0].merchant_poc_name,
          merchant_contact: res.data.data[0].merchant_contact,
          merchant_category: res.data.data[0].merchant_category,
          merchant_logo: res.data.data[0].merchant_logo,
          merchant_pan: res.data.data[0].merchant_pan,
          merchant_gst: res.data.data[0].merchant_gst,
          merchant_url: res.data.data[0].merchant_url,
          merchant_upi: res.data.data[0].merchant_upi,
          merchant_umid: res.data.data[0].merchant_umid,
          merchant_status: res.data.data[0].merchant_status,
          merchant_package: res.data.data[0].merchant_package,
          business_type: res.data.data[0].business_type,
          ifsc_code: res.data.data[0].ifsc_code,
          account_holder_name: res.data.data[0].account_holder_name,
          account_number: res.data.data[0].account_number,
          bank_name: res.data.data[0].bank_name,
          state: res.data.data[0].state,
          city: res.data.data[0].city,
          address: res.data.data[0].address,
          area_name: res.data.data[0].area_name,
          pin_code: res.data.data[0].pin_code
        });
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  // ----------------------------------get Edit data api Calling--------------------------


  const getEditData = () => {
    postApiReq(`/admin/edit-merchant`, { merchant_id: editId })
      .then((res) => {
        setImage(res.data.data[0].merchant_logo)
        setAddMerchants({
          merchant_name: res.data.data[0].merchant_name,
          merchant_email: res.data.data[0].merchant_email,
          merchant_location: res.data.data[0].merchant_location,
          merchant_poc_name: res.data.data[0].merchant_poc_name,
          merchant_contact: res.data.data[0].merchant_contact,
          merchant_category: res.data.data[0].merchant_category,
          merchant_logo: res.data.data[0].merchant_logo,
          merchant_pan: res.data.data[0].merchant_pan,
          merchant_gst: res.data.data[0].merchant_gst,
          merchant_url: res.data.data[0].merchant_url,
          merchant_upi: res.data.data[0].merchant_upi,
          merchant_umid: umid,
          merchant_status: status,
          merchant_package: res.data.data[0].merchant_package,
          business_type: res.data.data[0].business_type,
          ifsc_code: res.data.data[0].ifsc_code,
          account_holder_name: res.data.data[0].account_holder_name,
          account_number: res.data.data[0].account_number,
          bank_name: res.data.data[0].bank_name,
          state: res.data.data[0].state,
          city: res.data.data[0].city,
          address: res.data.data[0].address,
          area_name: res.data.data[0].area_name,
          pin_code: res.data.data[0].pin_code
        });
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  // ----------------------------------Update Brands Data-----------------------
  const handleUpdate = async () => {
    const data = new FormData();
    for (const [key, value] of Object.entries(addMerchant)) {
      data.append(key, value);
    }
    data.append("merchant_id", editId || merchentId);
    try {
      setErrors({});
      await MerchantsValidation.validate(addMerchant, {
        abortEarly: false,
      });
      postApiReq(`/admin/update-merchant`, data).then((res) => {
        if (res.status === 0) {
          dispatch(showToast({ type: "error", message: res.error }));
        }
        else {
          if (res.status) {
            dispatch(
              showToast({
                type: "success",
                message: "Merchant Data Update successfully.",
              })
            );
            navigate("/merchantstab");
          } else {
            dispatch(showToast({ type: "error", message: res.error.message }));
          }
        }
      }).catch((e) => {
        console.log("error", e);
      });
    } catch (error) {
      if (isYupError(error)) {
        console.log(error);
        setErrors(parseYupError(error));
      } else {
        console.log("error");
      }
    }
  };

  // ---------------------------------------------------Add Brandsdata---------------------------

  function readFile(file) {
    const render = new FileReader();
    render.onload = function (e) {
      $(`#show_choose_image`).attr("src", e.target.result);
    };
    render.readAsDataURL(file);
  }

  // -----------------------------------file upload -------------------------------------
  const handleImageUpload = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.size) {
        if (
          file.type === "image/png" ||
          file.type === "image/jpeg" ||
          file.type === "image/jpg"
        ) {
          let imageDataUrl = await readFile(file);
          setAddMerchants({ ...addMerchant, merchant_logo: file });
          setErrors({ ...errors, merchant_logo: "" })
        } else {
          dispatch(
            showToast({
              type: "error",
              message: "Please upload accepted file.",
            })
          );
          return false;
        }
      }
    }
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className="bg-gray-200 min-h-screen w-full text-[18px] py-12 pt-4">

        <form method="post" action="#" onSubmit={handleForm}>
          <div className="max-w-[1200px] mx-auto px-4"> <button onClick={() => navigate('/merchantstab')} > <IoMdArrowRoundBack className='text-[30px]' /></button></div>
          <div className="w-full tablet:text-[18px] text-[15px] max-w-[1200px] gap-20 mx-auto flex lg:justify-between tablet:justify-center lg:items-start tablet:items-center lg:flex-row flex-col">
            <div className="gap-6 tablet:w-[80%] lg:w-[100%] w-full px-4 mx-auto flex flex-col tablet:text-left">
              <div className="border-b-2 border-gray-400 tablet:mx-0 mx-4">
                <p className="font-bold mb-2 tablet:text-left text-center tablet:text-[18px] text-[16px]">
                  Create New Merchant Or Store Entity
                </p>
              </div>
              <div className="gap-x-6 flex tablet:flex-row tablet:mx-0 mx-4 flex-col">
                <label className="tablet:w-1/3 flex-shrink-0 tablet:leading-9 leading-7">
                  Store or brand name :
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="border-none w-full tablet:text-[16px] text-[14px]"
                    name="merchant_name"
                    value={addMerchant.merchant_name}
                    onChange={handleChange}
                    type="text"
                    placeholder="Enter name"
                  />
                  {errors ? (
                    <span className="text-red-600 text-[14px] leading-6">
                      {errors.merchant_name}
                    </span>
                  ) : (
                    ""
                  )}
                  <p className="p-0">
                  </p>
                </div>
              </div>

              <div className="gap-x-6 flex tablet:flex-row tablet:mx-0 mx-4 flex-col">
                <label className="w-1/3 flex-shrink-0 tablet:leading-9 leading-7">
                  Email address :
                </label>
                <input
                  className="tablet:text-[16px] text-[14px] border-none w-full "
                  type="email"
                  placeholder="Enter email address"
                  name="merchant_email"
                  value={addMerchant.merchant_email}
                  onChange={handleChange}
                />
              </div>

              <div className="gap-x-6 flex tablet:flex-row tablet:mx-0 mx-4 flex-col">
                <label className="w-1/3 flex-shrink-0 tablet:leading-9 leading-7">
                  Location :
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    type="text"
                    placeholder="Enter location"
                    name="merchant_location"
                    value={addMerchant.merchant_location}
                    onChange={handleChange}
                  />

                  {errors ? (
                    <span className="text-red-600 text-[14px] leading-6">
                      {errors.merchant_location}
                    </span>
                  ) : (
                    ""
                  )}

                </div>
              </div>

              <div className="flex gap-x-6 tablet:flex-row flex-col tablet:mx-0 mx-4">
                <label className="w-1/3 flex-shrink-0 tablet:leading-9 leading-7">
                  POC name :
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full  "
                    type="text"
                    placeholder="Enter POC name"
                    name="merchant_poc_name"
                    value={addMerchant.merchant_poc_name}
                    onChange={handleChange}
                  />

                  {errors ? (
                    <span className="text-red-600 text-[14px] leading-6">
                      {errors.merchant_poc_name}
                    </span>
                  ) : (
                    ""
                  )}

                </div>
              </div>

              <div className="gap-x-6 flex tablet:flex-row tablet:mx-0 mx-4 flex-col">
                <label className="w-1/3 flex-shrink-0 tablet:leading-8 leading-7">
                  Contact no :
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    name="merchant_contact"
                    type="number"
                    placeholder="Enter contact no."
                    value={addMerchant.merchant_contact}
                    onChange={(e) => {
                      (e.target.value.length <= 12) &&
                        setAddMerchants({
                          ...addMerchant,
                          merchant_contact: e.target.value,
                        });
                      setErrors({ ...errors, ['merchant_contact']: '' })
                    }
                    }
                  />

                  {errors ? (
                    <span className="text-red-600 text-[14px] leading-6">
                      {errors.merchant_contact}
                    </span>
                  ) : (
                    ""
                  )}

                </div>
              </div>

              <div className="gap-x-6 flex tablet:flex-row tablet:mx-0 mx-4 flex-col">
                <label className="w-1/3 flex-shrink-0 tablet:leading-8 leading-7">
                  State :
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    name="state"
                    type="text"
                    placeholder="Enter state"
                    value={addMerchant.state}
                    onChange={handleChange}
                  />

                  {errors ? (
                    <span className="text-red-600 text-[14px] leading-6">
                      {errors.state}
                    </span>
                  ) : (
                    ""
                  )}

                </div>
              </div>

              <div className="gap-x-6 flex tablet:flex-row tablet:mx-0 mx-4 flex-col">
                <label className="w-1/3 flex-shrink-0 tablet:leading-8 leading-7">
                  City :
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    name="city"
                    type="text"
                    placeholder="Enter city"
                    value={addMerchant.city}
                    onChange={handleChange}
                  />

                  {errors ? (
                    <span className="text-red-600 text-[14px] leading-6">
                      {errors.city}
                    </span>
                  ) : (
                    ""
                  )}

                </div>
              </div>

              <div className="gap-x-6 flex tablet:flex-row tablet:mx-0 mx-4 flex-col">
                <label className="w-1/3 flex-shrink-0 tablet:leading-8 leading-7">
                  Address :
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    name="address"
                    type="text"
                    placeholder="Enter address"
                    value={addMerchant.address}
                    onChange={handleChange}
                  />

                  {errors ? (
                    <span className="text-red-600 text-[14px] leading-6">
                      {errors.address}
                    </span>
                  ) : (
                    ""
                  )}

                </div>
              </div>

              <div className="gap-x-6 flex tablet:flex-row tablet:mx-0 mx-4 flex-col">
                <label className="w-1/3 flex-shrink-0 tablet:leading-8 leading-7">
                  Area name :
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    name="area_name"
                    type="text"
                    placeholder="Enter area name"
                    value={addMerchant.area_name}
                    onChange={handleChange}
                  />

                  {errors ? (
                    <span className="text-red-600 text-[14px] leading-6">
                      {errors.area_name}
                    </span>
                  ) : (
                    ""
                  )}

                </div>
              </div>

              <div className="gap-x-6 flex tablet:flex-row tablet:mx-0 mx-4 flex-col">
                <label className="w-1/3 flex-shrink-0 tablet:leading-8 leading-7">
                  Pin Code :
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    name="pin_code"
                    type="text"
                    placeholder="Enter pin code"
                    value={addMerchant.pin_code}
                    onChange={handleChange}
                  />

                  {errors ? (
                    <span className="text-red-600 text-[14px] leading-6">
                      {errors.pin_code}
                    </span>
                  ) : (
                    ""
                  )}

                </div>
              </div>

              <div className="gap-x-6 flex tablet:flex-row tablet:mx-0 mx-4 flex-col">
                <label className="w-1/3 flex-shrink-0 tablet:leading-9 leading-7">
                  Merchant URL :
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    name="merchant_url"
                    type="text"
                    placeholder="https://www.inburg.com/brandname"
                    value={addMerchant.merchant_url}
                    onChange={(e) => handleChange(e)}
                  />

                  {errors ? (
                    <span className="text-red-600 text-[14px] leading-6">
                      {errors.merchant_url}
                    </span>
                  ) : (
                    ""
                  )}

                </div>
              </div>


              <div className="gap-x-6 flex tablet:flex-row tablet:mx-0 mx-4 flex-col">
                <label className="tablet:w-1/3 tablet:leading-8 leading-7">
                  Select category :
                </label>
                <div className="flex flex-col">
                  <select
                    value={addMerchant.merchant_category}
                    name="merchant_category"
                    onChange={handleChange}
                    className="border-none tablet:text-[16px] text-[14px]"
                  >
                    <option value="">Select</option>
                    {
                      getAllCategories()
                    }
                  </select>
                  {errors ? (
                    <span className="text-red-600 text-[14px] leading-6">
                      {errors.merchant_category}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="flex tablet:flex-row flex-col gap-x-6  tablet:mx-0 mx-4">
                <label className="tablet:w-1/3 tablet:leading-9 leading-7">
                  Select Package:
                </label>
                <div>
                  <select
                    value={addMerchant.merchant_package}
                    name="merchant_package"
                    onChange={handleChange}
                    className="w-2/6 tablet:w-fit focus:border-gray-400 focus:ring-gray-500 tablet:text-[16px] text-[14px]"
                  >
                    <option value="free">Free</option>
                    <option value="starter">Starter</option>
                    <option value="advanced">Advanced</option>
                    <option value="elite">Elite</option>
                  </select>
                  {/* <p className='m-0 p-0'>{errors ? <span style={{ color: 'red' }}>{errors.merchant_package}</span> : ''}</p> */}
                </div>
              </div>

              <div className="flex tablet:flex-row flex-col gap-x-6  tablet:mx-0 mx-4">
                <label className="tablet:w-1/3 tablet:leading-9 leading-7">
                  Bussiness Type:
                </label>
                <div>
                  <select
                    value={addMerchant.business_type}
                    name="business_type"
                    onChange={handleChange}
                    className="w-2/6 tablet:w-fit focus:border-gray-400 focus:ring-gray-500 tablet:text-[16px] text-[14px]"
                  >
                    <option value="individual">Individual</option>
                    <option value="partnership">Partnership</option>
                    <option value="proprietorship">Proprietorship</option>
                    <option value="public_limited">Public limited</option>
                    <option value="private_limited">Private limited</option>
                  </select>
                  {/* <p className='m-0 p-0'>{errors ? <span style={{ color: 'red' }}>{errors.merchant_package}</span> : ''}</p> */}
                </div>
              </div>

              <div className="gap-x-6 flex tablet:flex-row tablet:mx-0 mx-4 flex-col">
                <label className="w-1/3 flex-shrink-0 tablet:leading-8 leading-7 ">
                  Upload logo :
                </label>
                <div className="flex flex-col w-full">

                  <label htmlFor="choose_image">
                    {!addMerchant.merchant_logo ?
                      <div className="cursor-pointer flex justify-center border border-gray-700">choose image</div>
                      :
                      <img id="show_choose_image" src={`${ENV.BASE_URL}/merchant_logo/${addMerchant.merchant_logo}`} className='w-[200px] h-[100px] cursor-pointer' />
                    }

                  </label>
                  <input
                    className="file:bg-red-500"
                    type="file"
                    id="choose_image"
                    hidden
                    name="merchant_logo"
                    onChange={(e) => {
                      handleImageUpload(e, "image");
                    }}
                  />
                  {errors ? (
                    <span className="text-red-600 text-[14px] leading-6">
                      {errors.merchant_logo}
                    </span>
                  ) : (
                    ""
                  )}
                  {/* <span className="mt-2"><a href={`${ENV.BASE_URL}/merchant_logo/${image}`} target="_blank">{image}</a></span> */}
                </div>
              </div>
            </div>
            <div className="gap-6 tablet:w-[80%] lg:w-[100%] w-full px-4 mx-auto flex flex-col tablet:text-left">
              <div className="border-b-2 border-gray-400  tablet:mx-0 mx-4">
                <p className="font-bold mb-2 tablet:text-left text-center tablet:text-[18px] text-[16px]">
                  Enter Legal Entity Details
                </p>
              </div>
              <div className="flex tablet:flex-row flex-col gap-x-6  tablet:mx-0 mx-4">
                <label className="w-1/3 flex-shrink-0 tablet:leading-9 leading-7">
                  PAN number :
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    type="text"
                    placeholder="EOBPP1424L"
                    value={addMerchant.merchant_pan}
                    name="merchant_pan"
                    onChange={handleChange}
                  />
                  {errors ? (
                    <span className="text-red-600 text-[14px] leading-6">
                      {errors.merchant_pan}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="flex tablet:flex-row flex-col gap-x-6  tablet:mx-0 mx-4">
                <label className="w-1/3 flex-shrink-0 tablet:leading-9 leading-7">
                  GST number :
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    type="text"
                    placeholder="18AABCU8603R1ZM"
                    value={addMerchant.merchant_gst}
                    name="merchant_gst"
                    onChange={handleChange}
                  />
                  {errors ? (
                    <span className="text-red-600 text-[14px] leading-6">
                      {errors.merchant_gst}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="flex tablet:flex-row flex-col gap-x-6  tablet:mx-0 mx-4">
                <label className="w-1/3 flex-shrink-0 tablet:leading-9 leading-7">
                  UPI id :
                </label>
                <div>
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    name="merchant_upi"
                    type="text"
                    value={addMerchant.merchant_upi}
                    placeholder="Enter UPI id"
                    onChange={handleChange}
                  />
                  {errors ? (
                    <span className="text-red-600 text-[14px] leading-6">
                      {errors.merchant_upi}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="flex tablet:flex-row flex-col gap-x-6  tablet:mx-0 mx-4">
                <label className="w-1/3 flex-shrink-0 tablet:leading-9 leading-7">
                  Select UMID :
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    type="text"
                    value={umid}
                    name="merchant_umid"
                    placeholder="Select UMID"
                    readOnly
                  />
                </div>
              </div>

              <div className="gap-x-6 flex tablet:flex-row tablet:mx-0 mx-4 flex-col">
                <label className="tablet:w-1/3 flex-shrink-0 tablet:leading-9 leading-7">
                  Bank Name :
                </label>
                {(status == -1) ?
                  <div className="flex flex-col w-full">
                    <input
                      className="border-none w-full tablet:text-[16px] text-[14px]"
                      name="bank_name"
                      value={addMerchant.bank_name}
                      onChange={handleChange}
                      type="text"
                      placeholder="Enter name"
                    />
                    {errors ? (
                      <span className="text-red-600 text-[14px] leading-6">
                        {errors.bank_name}
                      </span>
                    ) : (
                      ""
                    )}
                    <p className="p-0">
                    </p>
                  </div> :
                  <div className="flex flex-col w-full">
                    <input
                      className="border-none w-full tablet:text-[16px] text-[14px]"
                      name="bank_name"
                      value={addMerchant.bank_name}
                      onChange={handleChange}
                      type="text"
                      placeholder="Enter name"
                      readOnly
                    />
                    {errors ? (
                      <span className="text-red-600 text-[14px] leading-6">
                        {errors.bank_name}
                      </span>
                    ) : (
                      ""
                    )}
                    <p className="p-0">
                    </p>
                  </div>
                }
              </div>

              <div className="flex tablet:flex-row flex-col gap-x-6  tablet:mx-0 mx-4">
                <label className="w-1/3 flex-shrink-0 tablet:leading-9 leading-7">
                  IFSC Code :
                </label>
                {(status == -1) ?
                  <div>
                    <input
                      className="tablet:text-[16px] text-[14px] border-none w-full"
                      name="ifsc_code"
                      type="text"
                      value={addMerchant.ifsc_code}
                      placeholder="Enter IFSC Code"
                      onChange={handleChange}
                    />
                    {errors ? (
                      <span className="text-red-600 text-[14px] leading-6">
                        {errors.ifsc_code}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  :
                  <div>
                    <input
                      className="tablet:text-[16px] text-[14px] border-none w-full"
                      name="ifsc_code"
                      type="text"
                      value={addMerchant.ifsc_code}
                      placeholder="Enter IFSC Code"
                      onChange={handleChange}
                      readOnly
                    />
                    {errors ? (
                      <span className="text-red-600 text-[14px] leading-6">
                        {errors.ifsc_code}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>}

              </div>

              <div className="gap-x-6 flex tablet:flex-row tablet:mx-0 mx-4 flex-col">
                <label className="tablet:w-1/3 flex-shrink-0 tablet:leading-9 leading-7">
                  Account Holder Name :
                </label>
                {(status == -1) ?
                  <div className="flex flex-col w-full">
                    <input
                      className="border-none w-full tablet:text-[16px] text-[14px]"
                      name="account_holder_name"
                      value={addMerchant.account_holder_name}
                      onChange={handleChange}
                      type="text"
                      placeholder="Enter name"
                    />
                    {errors ? (
                      <span className="text-red-600 text-[14px] leading-6">
                        {errors.account_holder_name}
                      </span>
                    ) : (
                      ""
                    )}
                    <p className="p-0">
                    </p>
                  </div> :
                  <div className="flex flex-col w-full">
                    <input
                      className="border-none w-full tablet:text-[16px] text-[14px]"
                      name="account_holder_name"
                      value={addMerchant.account_holder_name}
                      onChange={handleChange}
                      type="text"
                      placeholder="Enter name"
                      readOnly
                    />
                    {errors ? (
                      <span className="text-red-600 text-[14px] leading-6">
                        {errors.account_holder_name}
                      </span>
                    ) : (
                      ""
                    )}
                    <p className="p-0">
                    </p>
                  </div>
                }
              </div>


              <div className="flex tablet:flex-row flex-col gap-x-6  tablet:mx-0 mx-4">
                <label className="w-1/3 flex-shrink-0 tablet:leading-9 leading-7">
                  Account Number :
                </label>
                {(status == -1) ?
                  <div className="flex flex-col w-full">
                    <input
                      className="tablet:text-[16px] text-[14px] border-none w-full"
                      type="text"
                      placeholder="Enter account number"
                      value={addMerchant.account_number}
                      name="account_number"
                      onChange={handleChange}
                    />
                    {errors ? (
                      <span className="text-red-600 text-[14px] leading-6">
                        {errors.account_number}
                      </span>
                    ) : (
                      ""
                    )}
                  </div> :
                  <div className="flex flex-col w-full">
                    <input
                      className="tablet:text-[16px] text-[14px] border-none w-full"
                      type="text"
                      placeholder="Enter account number"
                      value={addMerchant.account_number}
                      name="account_number"
                      onChange={handleChange}
                      readOnly
                    />
                    {errors ? (
                      <span className="text-red-600 text-[14px] leading-6">
                        {errors.account_number}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>}
              </div>


              <div
                className="flex gap-x-6  tablet:mx-0 mx-4"

              >
                <label className="tablet:w-1/3  tablet:text-[18px] text-[15px] ">
                  Status :
                </label>
                <div className="flex items-center gap-x-1"
                  onClick={(e) => handleChangeStatus(1)}
                >
                  <input
                    type="radio"
                    name="merchant_status"
                    id="status_id"
                    checked={addMerchant.merchant_status == '1' && true}

                  />
                  <label className="tablet:text-[18px] text-[15px]">
                    Active
                  </label>
                </div>

                <div className="tablet:pl-8"
                  onClick={(e) => handleChangeStatus(0)}
                >
                  <input
                    type="radio"
                    id="status_id"
                    name="merchant_status"
                    checked={addMerchant.merchant_status == '0' && true}
                  />
                  <label className="pl-1 tablet:text-[18px] text-[15px]">
                    Inactive
                  </label>
                </div>
              </div>
              <div className="flex gap-x-6 tablet:justify-start justify-center pb-10">
                <div className="flex items-center gap-x-6">
                  {flag === true ? (
                    <button
                      type="submit"
                      className="bg-gray-700 py-2 px-4 text-white tablet:mt-4 mt-4"
                    >
                      Save
                    </button>
                  ) : (
                    ""
                  )}
                  {flag === false ? (
                    <button
                      type="button"
                      className="bg-gray-700 py-2 px-2 text-white lg:mt-10"
                      onClick={handleUpdate}
                    >
                      Update
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default MerchantsData;
