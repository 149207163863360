import Cookies from "js-cookie";
import ENV from "./env";

export const isUserLoggedIn = () => {
  if (Cookies.get("user")) {
    return true;
  }
  return true;
};

export const getAllCategories = () => {
  let categories = {
    'ClothingAndApparel': 'Clothing And Apparel',
    'FastFood': 'Fast Food',
    'Footwear': 'Footwear',
    'RestaurantsAndBars': 'Restaurants And Bars',
    'Salon': 'Salon',
  }
  let options = [];
  Object.keys(categories).map((value, index) => {
    options.push(<option value={value} data-key={index}>{categories[value]}</option>);
  })
  return options;
}

export const imageHandler = (path, type) => {

  switch (type) {
    case 1:
      if (path && path.indexOf('http') === -1) {
        return `${ENV.BASE_URL}/qr_codes/${path}`;
      }
      else {
        return path;
      }
  }
}