import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
// import { postApiReq } from "../utils/ApiHandler";
import { getApiReq } from "../utils/ApiHandler";
import { showToast } from "../redux/action";
import Cookies from "js-cookie";
import moment from "moment";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import { StartEndDateValidation } from "../utils/Auth";
import { isYupError, parseYupError } from "../utils/Yup";
import Footer from "../component/Footer";

const CouponsViewPage = () => {
  const navigate = useNavigate()

  const { merchantId } = useParams()
  const [allCouponsData, setAllCouponsData] = useState([])
  const [errors, setErrors] = useState();
  const [redeemedStatus, setRedeemedStatus] = useState('')
  const [date, setDate] = useState({
    Start: '',
    End: ''
  });
  const dispatch = useDispatch()

  useEffect(() => {
    getAllCoupons();
  }, [redeemedStatus]);

  const getAllCoupons = () => {

    getApiReq(`/admin/get-user-coupons?merchant_id=${merchantId}&status=${redeemedStatus}&startDate=${date.Start}&endDate=${date.End}`).then((res) => {
      if (res.status) {
        setAllCouponsData(res.data.data)
      } else {
        dispatch(showToast({ type: "error", message: res.error }));
      }
    });
  }

  const getCouponData = async () => {
    try {
      setErrors({});
      await StartEndDateValidation.validate(date, {
        abortEarly: false,
      });
      getAllCoupons()

    } catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
        console.log(parseYupError(error));
      } else {
        console.log("error");
      }
    }
  }

  const handleLogout = () => {
    Cookies.remove('user');
    navigate('/')
  }
  const handleChange = (name, newValue) => {

    setDate({ ...date, [name]: moment(new Date(newValue)).format("YYYY-MM-DD hh:mm:ss a") })

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const redeemedCoupon = () => {
    if (redeemedStatus === '') {
      setRedeemedStatus(1);
    } else {
      setRedeemedStatus('');
    }
  }
  return (
    <div>
      <div className="flex justify-between bg-gray-700 items-center py-2">
        <div className="tablet:ml-4 ml-2">
          <NavLink to="/merchantsqrcode"><img className="tablet:w-[170px] w-[110px]" src="/image/Logo.png" /></NavLink>
        </div>

        <div className="flex  tablet:space-x-8 space-x-3 bg-gray-700 text-white tablet:text-[25px] text-[16px] tablet:mr-6 mr-4">
          <NavLink to="/couponstab">Coupons</NavLink>
          <NavLink to="/coupons1">Create-Coupons</NavLink>
          <NavLink to="/merchantstab">Merchants</NavLink>
          <NavLink to="/payments">Payments</NavLink>
          <NavLink to="/merchantsqrcode">QR code</NavLink>
          <button onClick={handleLogout}>Logout</button>
        </div>
      </div>

      <div className="flex tablet:flex-row flex-col tablet:p-4 justify-between item-cneter p-3 gap-5 bg-[#CCCCCC] h-[110px]">
        {/* <div className="">
          <input
            className="w-[220px] focus:border-gray-300 focus:ring-gray-400"
            type="text"
            name="daterange"
            value=""
          />
        </div> */}
        <div className="flex gap-5">

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="flex tablet:flex-row flex-col  tablet:text-[18px] text-[16px] tablet:leading-0 leading-10 ">

              <div>
                {/* <DateTimePicker
                label="Start"
                value={date.Start}
                minDate={new Date()}
                onChange={(e) => handleChange("Start", e)}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      color: "gray",
                      "& label.Mui-focused": {
                        color: "gray",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "gray",
                      },

                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "white",
                        },
                        "&:hover fieldset": {
                          borderColor: "white",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "gray",
                        },
                        "&.Mui-error fieldset": {
                          borderColor: "gray",
                        },
                        "& fieldset": {
                          borderColor: "gray",
                        },
                      },
                    }}
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "dd/mm/yyyy hh:mm (am|pm)",
                    }}
                  />
                )}
              /> */}
                <DateTimePicker
                  label="Start date"
                  value={date.Start}
                  onChange={(e) => handleChange("Start", e)}
                  renderInput={(params) => <TextField {...params} />}
                />
                <p className="tablet:text-[16px] text-[13px]">
                  {errors ? (
                    <span style={{ color: "red" }}>
                      {errors.Start}
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            </div>

            <div className="flex tablet:flex-row flex-col tablet:leading-0 leading-10">
              <div>
                {/* <DateTimePicker
                className=""
                label="End"
                value={date.End}
                minDate={new Date()}
                onChange={(e) => handleChange("End", e)}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      color: "gray",
                      "& label.Mui-focused": {
                        color: "gray",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "gray",
                      },

                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "white",
                        },
                        "&:hover fieldset": {
                          borderColor: "white",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "gray",
                        },
                        "&.Mui-error fieldset": {
                          borderColor: "gray",
                        },
                        "& fieldset": {
                          borderColor: "gray",
                        },
                      },
                    }}
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "dd/mm/yyyy hh:mm (am|pm)",
                    }}
                  />
                )}
              /> */}
                <DateTimePicker
                  label="End date"
                  value={date.End}
                  onChange={(e) => handleChange("End", e)}
                  renderInput={(params) => <TextField {...params} />}
                />
                <p className="tablet:text-[16px] text-[13px]">
                  {errors ? (
                    <span style={{ color: "red" }}>
                      {errors.End}
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            </div>
          </LocalizationProvider>


          <button className="bg-gray-500 w-[150px] h-[56px] rounded-md text-white py-2 px-2.5 tablet:text-[18px] text-[14px]" onClick={getCouponData} >
            Search
          </button>
        </div>
        <div className="flex items-center gap-4 tablet:mr-4 tablet:mt-0 mt-3 justify-end">
          <label
            for="default-toggle"
            class="inline-flex relative items-center cursor-pointer"
          >
            <input
              type="checkbox"
              value={redeemedStatus}
              id="default-toggle"
              class="sr-only peer"
              onChange={redeemedCoupon}
            />
            <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
            <span class="ml-3 tablet:text-[18px] text-[15px]">
              Redeemed only
            </span>
          </label>
        </div>

      </div>


      <div className="">
        <table className="tablet:w-[200%] lg:w-[100%]">
          <thead className="sticky top-0">
            <tr className="bg-gray-600 text-white tablet:text-[20px] text-[16px]">
              <th className="w-1/10 border-l-2 border-b-2 p-3">Coupon Code</th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Redeemed</th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Issued</th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Issued Via</th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Coupon value<span> (&#8377;)</span></th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Net sales value<span>(&#8377;)</span></th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Merchant Payout <span>(&#8377;)</span></th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Inburg Commission<span>(&#8377;)</span></th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Payment Status</th>
            </tr>
          </thead>
          <tbody>
            {
              (allCouponsData?.length > 0) ?
                allCouponsData?.map((item, index) => {
                  return (
                    <tr className="tablet:text-[17px] text-[13px]">
                      <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{item.coupon_name}</td>
                      <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                        {(item.redeemed_at === null) ? '--' : moment(new Date(item.redeemed_at)).format("DD-MM-YYYY hh:mm:ss a")}
                      </td>
                      <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                        {moment(new Date(item.coupon_created_at)).format("DD-MM-YYYY hh:mm:ss a")}
                      </td>
                      <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                        {(item.merchant_name) ? item.merchant_name : '--'}
                      </td>
                      <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                        {`${item.offer_discount} ${(item.offer_type === 'FLAT') ? item.offer_type : '%'} `}
                      </td>
                      <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                        {(item.net_amount) ? item.net_amount : 0}
                      </td>
                      <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                        {(item.merchant_amount) ? item.merchant_amount : 0}
                      </td>
                      <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                        {(item.total_commission) ? item.total_commission : 0}
                      </td>
                      <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                        {(item.payment_status) ? item.payment_status : 'U/A'}
                      </td>
                    </tr>
                  )
                }) :
                <tr className="tablet:text-[18px] text-[14px]">
                  <td colSpan={8} className=" text-center border-b-2 border-gray-300 py-2">data not found</td>
                </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CouponsViewPage;
