import { TextField } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { getApiReq } from "../utils/ApiHandler";
import { showToast } from "../redux/action";
import { AuthContext } from "../context/Context";
import moment from "moment";
import { Coupons1Validation } from "../utils/Auth";
import { isYupError } from "../utils/Yup";
import { parseYupError } from "../utils/Yup";
import Cookies from "js-cookie";

const Coupons1 = () => {
  const [value, setValue] = useState(null);
  const [errors, setErrors] = useState();
  const { couponsData, setCouponsData } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleChange = (name, newValue) => {
    if (name === "couponCount") {
      setCouponsData({
        ...couponsData,
        [name]: newValue.target.value,
      });
    } else {
      setCouponsData({
        ...couponsData,
        [name]: moment(new Date(newValue)).format("YYYY-MM-DD hh:mm:ss a"),
      });
    }
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleClick = async () => {
    try {
      setErrors({});
      await Coupons1Validation.validate(couponsData, {
        abortEarly: false,
      });
      setCouponsData(couponsData);
      navigate("/coupons2");
    } catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
        console.log(parseYupError(error));
      } else {
        console.log("error");
      }
    }
  };

  // -----------------------------getAllMerchants----------------------------------
  const dispatch = useDispatch();

  const [merchantAllData, setMerchantAllData] = useState([]);

  useEffect(() => {
    getAllMerchants();
  }, []);

  const getAllMerchants = () => {
    getApiReq(`/admin/Get-all-merchant`).then((res) => {
      if (res.status) {
        setMerchantAllData(res.data.data);
      } else {
        dispatch(showToast({ type: "error", message: res.error }));
      }
    });
  };

  const handleSelect = (event) => {
    console.log(event);
    setCouponsData({ ...couponsData, merchantId: Number(event.target.value) });
    setErrors({
      ...errors,
      merchantId: "",
    });
  };

  const handleLogout = () => {
    Cookies.remove("user");
    navigate("/");
  };
  return (
    <div>

      <div className="bg-gray-700 flex justify-between items-center py-2">
        <div>
          <NavLink to="/merchantsqrcode">
            <img
              className="tablet:w-[170px] w-[110px] tablet:ml-4 ml-2"
              src="/image/Logo.png"
            />
          </NavLink>
        </div>

        <div className="tablet:mr-6 mr-3 tablet:space-x-8 space-x-2">
          <button
            className="bg-white py-1 px-2  tablet:text-[18px] text-[13px] text-gray-600"
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      </div>
      <div className="bg-[#CCCCCC] flex gap-x-10 tablet:p-4 p-2">
        <ul className="flex tablet:ml-2 ml-1 tablet:gap-x-16 gap-x-2 tablet:text-[18px] text-[13px] text-gray-600">
          <li className="text-black">
            <NavLink to="/coupons1">General Ruleset</NavLink>
          </li>
          <li className="hover:text-black">
            <NavLink to="/coupons2">Usage Restrictions</NavLink>
          </li>
          <li className="hover:text-black"> Add Payment Method</li>
        </ul>
      </div>

      <div className="flex justify-center items-center  bg-gray-100 lg:h-[660px] tablet:h-[889px] h-[87vh]">
        <div className="mx-auto  flex justify-center">
          <div className="tablet:w-[500px]">
            <div className="tablet:space-y-8 space-y-6 tablet:mx-0 mx-4">
              <div className="flex  tablet:flex-row flex-col tablet:leading-0 leading-10">
                <label className="w-1/2 flex-shrink-0 tablet:text-[18px] text-[16px]">
                  Merchant Name :
                </label>

                <div>
                  <select
                    className="tablet:w-[250px] w-[270px] h-[56px] rounded-[5px]"
                    id="merchantId"
                    value={couponsData?.merchantId || ""}
                    onChange={(e) => handleSelect(e)}
                  >
                    <option value="">Merchant Name</option>
                    {merchantAllData.map((item, index) => {
                      return (
                        <option value={item.merchant_id} key={index}>
                          {item.merchant_name}
                        </option>
                      );
                    })}
                  </select>
                  <p className="tablet:text-[16px] text-start text-[13px]">
                    {errors ? (
                      <span style={{ color: "red" }}>{errors.merchantId}</span>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
              </div>

              <div className="flex  tablet:flex-row flex-col tablet:leading-0 leading-10">
                <label className="w-1/2 flex-shrink-0 tablet:text-[18px] text-[16px]">
                  Total Coupon :
                </label>

                <div className="relative w-[230px]">
                  <input
                    className="tablet:w-[280px] w-[220px] pr-[25px] tablet:text-[16px] text-[13px]"
                    type="number"
                    name="couponCount"
                    // value={couponsData.couponCount || ""}
                    placeholder="Enter Total Coupon Count"
                    onChange={(e) => handleChange("couponCount", e)}
                  />
                  <span className="tablet:text-[16px] text-[13px]">
                    {errors ? (
                      <span style={{ color: "red" }}>
                        {errors.couponCount}
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </div>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div className="flex tablet:flex-row flex-col  tablet:text-[18px] text-[16px] tablet:leading-0 leading-10 ">
                  <label className="w-1/2 flex-shrink-0">Coupon Start :</label>
                  <div>
                    <DateTimePicker
                      label="Coupon Start Date"
                      value={couponsData.couponStart}
                      minDate={new Date()}
                      onChange={(e) => handleChange("couponStart", e)}
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            color: "gray",
                            "& label.Mui-focused": {
                              color: "gray",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "gray",
                            },

                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "white",
                              },
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "gray",
                              },
                              "&.Mui-error fieldset": {
                                borderColor: "gray",
                              },
                              "& fieldset": {
                                borderColor: "gray",
                              },
                            },
                          }}
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "mm/dd/yyyy hh:mm (am|pm)",
                          }}
                        />
                      )}
                    />
                    <p className="tablet:text-[16px] text-[13px]">
                      {errors ? (
                        <span style={{ color: "red" }}>
                          {errors.couponStart}
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                </div>

                <div className="flex tablet:flex-row flex-col tablet:leading-0 leading-10">
                  <label className="w-1/2 flex-shrink-0 tablet:text-[18px] text-[16px]">
                    Coupon Expiry :
                  </label>
                  <div>
                    <DateTimePicker
                      className=""
                      label="Coupon Expiry Date"
                      value={couponsData.couponExpiry}
                      minDate={new Date()}
                      onChange={(e) => handleChange("couponExpiry", e)}
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            color: "gray",
                            "& label.Mui-focused": {
                              color: "gray",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "gray",
                            },

                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "white",
                              },
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "gray",
                              },
                              "&.Mui-error fieldset": {
                                borderColor: "gray",
                              },
                              "& fieldset": {
                                borderColor: "gray",
                              },
                            },
                          }}
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "mm/dd/yyyy hh:mm (am|pm)",
                          }}
                        />
                      )}
                    />
                    <p className="tablet:text-[16px] text-[13px]">
                      {errors ? (
                        <span style={{ color: "red" }}>
                          {errors.couponExpiry}
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                </div>
              </LocalizationProvider>

              <div className=" flex tablet:justify-center justify-start tablet:pl-10 tablet:ml-10">
                <button
                  className="bg-gray-700 mt-2 text-white tablet:px-4 px-3 py-2 tablet:text-[20px] text-[15px]"
                  onClick={handleClick}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coupons1;
