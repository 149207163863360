import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import { postApiReq } from "../utils/ApiHandler";
import { showToast } from "../redux/action";
import { getApiReq } from "../utils/ApiHandler";
import { imageHandler } from "../utils/basicReq";
import { saveAs } from 'file-saver'
import Cookies from "js-cookie";
import { BsEyeFill } from 'react-icons/bs'
import Footer from "../component/Footer";

const MerchantsQrcode = () => {
  const [generateUmid, setGenerateUmid] = useState([]);
  const [search, setSearch] = useState('');
  const [umidSearch, setUmidSearch] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleUmid = () => {
    postApiReq(`/admin/create-qr-code`, { limit_generate: 5 }).then((res) => {
      if (res.data.status === 0) {
        dispatch(showToast({ type: "error", message: res.data.error }));
      } else {
        if (res.status) {
          dispatch(
            showToast({
              type: "success",
              message: "Merchant UMID Generate successfully.",
            })
          );
          getAllUmid();
        } else {
          dispatch(showToast({ type: "error", message: res.error }));
        }
      }
    });
  };

  useEffect(() => {
    getAllUmid();
  }, [search, umidSearch]);

  const getAllUmid = () => {

    getApiReq(`/admin/get-all-merchant_umid?search=${search}&umidSearch=${umidSearch}`).then((res) => {
      if (res.status) {
        setGenerateUmid(res.data.data);
      } else {
        dispatch(showToast({ type: "error", message: res.error }));
      }
    });
  };

  const downloadImage = (name) => {
    const imagePath = imageHandler(name, 1)
    saveAs(imagePath, 'image.jpg')
  }


  const handleChange = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  const umidHandleChange = (event) => {
    const { value } = event.target;
    setUmidSearch(value);
  }


  // -------------------------------Logout-------------------
  const handleLogout = () => {
    Cookies.remove('user');
    navigate('/')
  }

  return (
    <div className="md:text-[20px] text-[15px]">
      <div className="border-2 border-gray-300">
        <div className="w-full bottom-2 border-b-2  border-gray-400 bg-gray-700 text-white flex justify-between py-2">
          <div className="flex items-center">
            <img
              className=" tablet:w-[170px] w-[110px] tablet:ml-4 ml-2"
              src="/image/Logo.png"
            />
          </div>

          <div className="flex items-center">
            <div className="md:mr-6 mr-2">
              <ul className="flex tablet:space-x-8 space-x-4 tablet:text-[23px] text-[text-20px]">
                <li>
                  <NavLink to="/couponstab">Coupons</NavLink>
                </li>
                <li>
                  <NavLink to="/coupons1">Create-Coupons</NavLink>
                </li>
                <li>
                  <NavLink to="/merchantstab">Merchants</NavLink>
                </li>
                <li>
                  <NavLink to="/payments">Payments</NavLink>
                </li>
                <li>
                  <NavLink to="/merchantsqrcode">QR code</NavLink>
                </li>
                <li>
                  <button onClick={handleLogout}>Logout</button>
                </li>
              </ul>
            </div>
          </div>

        </div>

        <div className="flex justify-between tablet:p-4 p-2 bg-[#CCCCCC] text-gray-700 text-[18px]">

          <div className="flex gap-10">

            <div className="flex  justify-start bg-[#CCCCCC]">
              <div className="tablet:mr-2">
                <input
                  className="w-[120px] focus:border-gray-300 focus:ring-gray-400"
                  type="text"
                  name="umid"
                  placeholder="UMID"
                  onChange={umidHandleChange}
                />
              </div>
            </div>

            <div className="flex  justify-start bg-[#CCCCCC]">
              <div className="tablet:mr-2">
                <input
                  className="w-[220px] focus:border-gray-300 focus:ring-gray-400"
                  type="text"
                  name="merchant_search"
                  placeholder="Store/Brand Name"
                  onChange={handleChange}
                />
              </div>
            </div>

          </div>

          {/* <select className="focus:border-gray-100 focus:ring-gray-300 tablet:text-[18px] text-[14px] tablet:ml-2">
            <option>UMID</option>
            <option>a</option>
            <option>b</option>
            <option>c</option>
            <option>d</option>
          </select> */}
          <button
            className="bg-gray-500 tablet:px-3 tablet:py-2 px-2 tablet:text-[18px] text-[14px] text-white "
            onClick={handleUmid}
          >
            Generate UMID
          </button>
        </div>
        {/* tablet:overflow-x-auto overflow-x-auto  md:overflow-x-hidden */}
        <div className="w-full ">
          <table className="lg:w-full tablet:w-[150%] w-[230%]">
            <thead className="bg-gray-600 text-white tablet:text-[20px] text-[16px] sticky -top-[1px]">
              <tr>
                <th className="w-1/12 font-bold border-2 p-3">Sr. no</th>
                <th className="w-1/4 font-bold border-2 p-3 border-l-0">
                  {" "}
                  Unique Merchants Id (UMID)
                </th>
                <th className="w-1/3 font-bold border-2 p-3">
                  Unique QR Codes
                </th>
              </tr>
            </thead>
            <tbody className="">
              {generateUmid?.length > 0 ?
                generateUmid?.map((item, index) => (
                  <tr key={index}>
                    <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300 ">
                      <div className="flex space-x-8 justify-center">
                        <div>{index + 1}</div>
                      </div>
                    </td>

                    <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300 ">
                      <div className="flex space-x-8 justify-center">
                        <div>{item.merchant_umid}</div>
                      </div>
                      <p className="text-sky-600">{item.merchant_name}</p>
                    </td>

                    <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300 ">
                      <div className="flex justify-between space-x-8 items-center px-10">
                        <div>
                          <img
                            className="tablet:w-[80px] w-[60px]"
                            src={imageHandler(item.merchant_qr_name, 1)}
                          ></img>
                        </div>

                        <button onClick={() => downloadImage(item.merchant_qr_name)}><BsEyeFill style={{ fontSize: '25px', color: '#7a8289' }} /></button>

                        <div>
                          {item.merchant_code_status == -1 ? (
                            <button
                              className="bg-gray-500 text-white w-[200px] py-2 px-3 tablet:text-[18px] text-[14px]"
                              onClick={() =>
                                navigate(
                                  `/merchantsdata/${item.merchant_umid}/${item?.merchant_uid}/${item.merchant_code_status}`
                                )
                              }
                            >
                              Add merchant
                            </button>
                          ) : (
                            <button className="bg-gray-500 w-[200px] text-white py-2 px-2.5 tablet:text-[18px] text-[14px]" onClick={() => navigate(`/editmerchantsdetails/${item.merchant_uid}/${item.merchant_umid}`)}>
                              Edit merchant
                            </button>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                )) :
                <tr className="tablet:text-[18px] text-[14px]">
                  <td colSpan={3} className=" text-center border-b-2 border-gray-300 py-2">data not found</td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </div >
  );
};

export default MerchantsQrcode;
