import React from "react";
import Footer from "../component/Footer";
import Navbar from "../component/Navbar";

const MerchantsCoupon = () => {
  const datatable = [
    {
      name: "DL45XY59",
      coupon: "20-06-2022",
      coupons: "15-06-2022",
      redeemed: "50.00",
      Remain: "1150.00",
      Demo: "97.75",
    },
    {
      name: "DL23GH15",
      coupon: "19-06-2022",
      coupons: "15-06-2022",
      redeemed: "50.00",
      Remain: "1750.00",
      Demo: "148.75",
    },
    {
      name: "DL17IN67",
      coupon: "19-06-2022",
      coupons: "15-06-2022",
      redeemed: "50.00",
      Remain: "1325.00",
      Demo: "112.63",
    },
    {
      name: "MH45XY22",
      coupon: "-",
      coupons: "15-06-2022",
      redeemed: "50.00",
      Remain: "-",
      Demo: "-",
    },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
  ];
  return (
    <div>
      <Navbar />

      <div className="flex tablet:flex-row flex-col tablet:p-4 p-3 justify-between bg-[#CCCCCC]">
        <div className="flex tablet:flex-row flex-col tablet:space-x-8 tablet:ml-2">
          <div>
            <select className="focus:border-gray-300 focus:ring-gray-400">
              <option>Merchants name</option>
              <option>A</option>
              <option>B</option>
              <option>C</option>
              <option>D</option>
            </select>
          </div>
          <div className="tablet:mr-10">
            <input
              className="w-[220px] focus:border-gray-300 focus:ring-gray-400"
              type="text"
              name="daterange"
              value=""
            />
          </div>
        </div>
        <div className="flex items-center gap-4 tablet:mr-4 tablet:mt-0 mt-2">
          <label
            for="default-toggle"
            class="inline-flex relative items-center cursor-pointer"
          >
            <input
              type="checkbox"
              value=""
              id="default-toggle"
              class="sr-only peer"
            />
            <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
            <span class="ml-2 tablet:text-[18px] text-[15px]">
              Reedemeed only
            </span>
          </label>
        </div>
      </div>

      <div className="">
        <table className="tablet:w-[200%] lg:w-[100%]">
          <thead className="sticky top-0">
            <tr className="bg-gray-600 text-white tablet:text-[20px] text-[16px]">
              <th className="w-1/10 border-l-2 border-b-2 p-3">Coupon id</th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Redeemed</th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Issued</th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">
                {" "}
                Coupon value<span> (&#8377;)</span>
              </th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">
                Net sales value<span>(&#8377;)</span>
              </th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">
                Net commission<span> (&#8377;)</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {datatable.map((item) => (
              <tr className="tablet:text-[17px] text-[13px]">
                <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                  {item.name}
                </td>
                <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                  {item.coupon}
                </td>
                <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                  {item.coupons}
                </td>
                <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                  {item.redeemed}
                </td>
                <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                  {item.Remain}
                </td>

                <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                  {item.Demo}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div >
  );
};

export default MerchantsCoupon;
