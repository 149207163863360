
import { IS_LOADING_TRUE, IS_LOADING_FALSE } from "../action/actionConstants";


const initialState = {
    isLoading: false
};


const LoaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case IS_LOADING_TRUE:
            return {
                isLoading: true
            };
        case IS_LOADING_FALSE:
            return {
                isLoading: false
            };
        default:
            return state
    }


}
export default LoaderReducer