import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/Context";
import { postApiReq } from "../utils/ApiHandler";
import { showToast } from "../redux/action";
import { Coupons2Validation } from "../utils/Auth";
import { isYupError } from "../utils/Yup";
import { parseYupError } from "../utils/Yup";
import Cookies from "js-cookie";
import Footer from "../component/Footer";

const Coupons2 = () => {
  const [errors, setErrors] = useState();
  const { couponsData, setCouponsData } = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCouponsData({ ...couponsData, [name]: value });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSave = async () => {

    console.log(couponsData)

    try {
      setErrors({});
      await Coupons2Validation.validate(couponsData, {
        abortEarly: false,
      });
      setCouponsData(couponsData);
    } catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
      } else {
        console.log("error");
      }
    }
    try {
      setErrors({});
      await Coupons2Validation.validate(couponsData, {
        abortEarly: false,
      });
      postApiReq(`/admin/create-coupon`, couponsData)
        .then((res) => {
          if (res.data.status === 0) {
            dispatch(showToast({ type: "error", message: res.data.error }));
          } else {
            if (res.status) {
              dispatch(
                showToast({
                  type: "success",
                  message: "Create Coupon successfully.",
                })
              );
              navigate("/couponstab");
            } else {
              dispatch(showToast({ type: "error", message: res.error.message }));
            }
          }
        })
        .catch((e) => {
          console.log("error", e);
        });
    } catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
        console.log(parseYupError(error));
      } else {
        console.log("error");
      }
    }
  };
  const handleLogout = () => {
    Cookies.remove("user");
    navigate("/");
  };

  return (
    <div className="w-full min-h-screen">
      <div >
        <div>
          <div className="bg-gray-700 flex justify-between items-center py-2">
            <div>
              <img
                className="tablet:w-[170px] w-[110px] tablet:ml-4 ml-2"
                src="/image/Logo.png"
              />
            </div>
            <div className="tablet:mr-6 mr-3 tablet:space-x-8 space-x-2">
              <button
                className="bg-white py-1 px-2  tablet:text-[18px] text-[13px] text-gray-600"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          </div>
          <div className="bg-[#CCCCCC] flex tablet:p-4 p-2">
            <ul className="flex tablet:ml-2 ml-1 tablet:gap-x-16 gap-x-2 tablet:text-[18px] text-[13px] text-gray-600">
              <li className=" hover:text-black">
                <NavLink to="/coupons1">General Ruleset</NavLink>
              </li>
              <li className="text-black">
                <NavLink to="/coupons2">Usage Restrictions</NavLink>
              </li>

              <li className="hover:text-black"> Add Payment Method</li>
            </ul>
          </div>
        </div>
        {/* nav end */}
        <div className="grid lg:min-h-[660px] tablet:min-h-[86vh] min-h-[90vh] place-items-center bg-gray-100">
          <div className="text-gray-700 tablet:w-[700px] w-full tablet:text-[18px] text-[14px]">
            <div className="flex tablet:flex-row flex-col tablet:mx-0 mx-4">
              <div className="flex tablet:w-[40%]">
                <div className="w-full">
                  <p className="text-center">
                    <span className="tablet:text-[25px] text-[20px]">
                      <sup>AOV 1*</sup>
                    </span>{" "}
                    <br />
                    <span className="tablet:text-[13px] text-[10px]">
                      (Average order value)
                    </span>
                  </p>
                </div>
              </div>
              <div className="flex flex-col tablet:w-[100%]">
                <div className="tablet:space-y-5 relative space-y-4 tablet:text-[16px] text-[13px] tablet:mt-0 mt-4">

                  <div className="relative w-[230px]">
                    <input
                      className="tablet:w-[280px] w-[220px] pr-[25px] tablet:text-[16px] text-[13px]"
                      type="number"
                      name="couponDiscount"
                      value={couponsData.couponDiscount}
                      placeholder="Enter denomination in Rs"
                      onChange={handleChange}
                    />
                    <span className="tablet:text-[16px] text-[13px]">
                      {errors ? (
                        <span style={{ color: "red" }}>
                          {errors.couponDiscount}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                    {/* <select
                    className="w-[130px] inline-flex  items-center cursor-pointer absolute left-[370px] top-[50%] translate-y-[-50%]"
                    name=""
                    id=""
                  >
                    {" "}
                    <option value="FLAT">Flat</option>
                    <option value="PERCENT" className="">
                      Percent
                    </option>
                  </select> */}
                    {/* <label
                    htmlFor="switch-coupon"
                    className="inline-flex  items-center cursor-pointer absolute right-4 top-[50%] translate-y-[-50%]"
                  >

                    {/* <input
                      type="checkbox"
                      value=""
                      id="switch-coupon"
                      className="sr-only peer"
                    /> */}
                    {/* <div className="tablet:w-11 w-8 tablet:h-6 h-5 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full tablet:after:h-5 after:h-4 tablet:after:w-5 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div> */}
                    {/* </label> */}
                  </div>
                  <div className="flex justify-end absolute tablet:right-[50px] right-[10px] tablet:top-[-20px] top-[-16px]">
                    <select
                      className="tablet:text-[16px] text-[13px] tablet:w-[150px] w-[100px]"
                      name="couponType"
                      id=""
                      onChange={handleChange}
                    >
                      <option value="">Select Offer</option>
                      <option value="FLAT">FLAT</option>
                      <option value="PERCENT">PERCENT</option>
                    </select>
                  </div>
                  <span className="tablet:text-[16px] text-[13px] m-0">
                    {errors ? (
                      <span style={{ color: "red" }}>{errors.couponType}</span>
                    ) : (
                      ""
                    )}
                  </span>

                  <input
                    type="number"
                    className="tablet:w-[280px] w-[220px] tablet:text-[16px] text-[13px]"
                    name="minAmount"
                    value={couponsData.minAmount}
                    placeholder="Enter amount more than Rs100"
                    onChange={handleChange}
                  />
                  <span className="tablet:text-[16px] text-[13px] m-0">
                    {errors ? (
                      <span style={{ color: "red" }}>{errors.minAmount}</span>
                    ) : (
                      ""
                    )}
                  </span>

                  <input
                    type="number"
                    className="tablet:w-[280px] w-[220px] tablet:text-[16px] text-[13px]"
                    name="maxAmount"
                    value={couponsData.maxAmount}
                    placeholder="Enter amount less than Rs10000"
                    onChange={handleChange}
                  />
                  <span className="tablet:text-[16px] text-[13px]">
                    {errors ? (
                      <span style={{ color: "red" }}>{errors.maxAmount}</span>
                    ) : (
                      ""
                    )}
                  </span>

                </div>
              </div>

            </div>
            <div className="flex tablet:flex-row flex-col  my-4">
              <div className="flex tablet:w-[40%]">Inburge Commission </div>
              <div className="flex flex-col tablet:w-[100%]">
                <input
                  type="number"
                  className="tablet:w-[280px] w-[220px] tablet:text-[16px] text-[13px]"
                  name="inburgPercent"
                  value={couponsData.inburgPercent}
                  placeholder="Enter value in percent"
                  onChange={handleChange}
                />
                <span className="tablet:text-[16px] text-[13px]">
                  {errors ? (
                    <span style={{ color: "red" }}>{errors.inburgPercent}</span>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </div>


            <div className="flex  mt-10 tablet:mx-0 mx-4 tablet:space-x-0 space-x-10">
              <div className="flex tablet:w-[40%]">
                <label className="text-center w-full">
                  Status<sup>*</sup>
                </label>
              </div>

              <div className="flex flex-col gap-6 tablet:w-[100%]">
                <div
                  className="flex tablet:text-[16px] gap-6 text-[13px]"
                  onChange={handleChange}
                >
                  <div className="">
                    <input type="radio" name="couponStatus" value={1} />
                    <label className="ml-1">Active</label>
                  </div>
                  <div className="">
                    <input type="radio" name="couponStatus" value={0} />
                    <label className="ml-1">Inactive</label>
                  </div>
                </div>
                <div className="flex mt-4 tablet:justify-start w-full justify-center">
                  <button
                    className="bg-gray-700 text-white tablet:px-4 px-3 py-2"
                    onClick={handleSave}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coupons2;
